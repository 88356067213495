import { TranslateLoader } from '@ngx-translate/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class CustomTranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient, private store: Store) {}

  getTranslation(lang: string): Observable<any> {
    const headers = new HttpHeaders({
      Language: lang,
      skip: 'true'
    });

    return this.http.get(`${environment.apiUrl}/language-lines${lang === 'TECH' ? '?technical=true' : ''}`, {
      headers
    });
  }
}
