import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { LanguageService } from '../../services/language.service';
import { update } from './user-actions';

@Injectable()
export class UserEffects {
  updateLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(update),
      tap((action: { user: User }) => {
        const user: User = action.user;

        if (user.locale !== this.languageService.currentLang()) {
          this.languageService.setLanguage(user.locale);
        }
      }),
      map((action: { user: User }) => ({
        type: '[User] Update Language Success',
        user: action.user
      }))
    )
  );

  constructor(private actions$: Actions, private languageService: LanguageService) {}
}
